<template>
    <div class="aa-lectura mb-3" v-if="oferta">
        <div class="aa-lectura-img" :style="'background-image: url('+oferta.portada+')'">
            <!-- <img :src="oferta.portada" v-on:load="onLoaded" v-show="loaded"/> -->
            <!-- <div style="width:100%; height: 327px" v-show="!loaded" class="portada-area">
                <LoadingVue />
            </div> -->
        </div>
        <div class="aa-lectura-txt aa-text-h2 mb-1 mt-3 text-start mt-2">
            {{oferta.nombre}}
        </div>
        <div class="aa-oferta-content">
            <div class="aa-lectura-doc text-start" v-html="oferta.descripcion"></div> 
            <div class="docs" v-if="oferta.documentos">
                <DescargaDocumento :documentos="oferta.documentos" :titulo="'Documentos'" />
            </div>
        </div>
    </div>
    <div v-else>
        <PrensaSk />
    </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { watch, defineAsyncComponent } from 'vue';
import useOfertas from '../composables/useOfertas'

import PrensaSk from '../components/skeleton/PrensaSk.vue'


export default {
    components: {
        PrensaSk,
        DescargaDocumento: defineAsyncComponent(() => import("../components/Area/DescargaDocumento.vue")),
    },
    setup(){
        const route = useRoute()
        const { oferta, detalleOferta, loaded } = useOfertas()
        detalleOferta(route.params.id)
        
        watch(
            () => route.params.id,
            () =>{ 
                if (route.params.id !== undefined){
                    detalleOferta(route.params.id)
                }
            }
        )

        return{
            oferta,
            loaded,
            onLoaded: () => loaded.value = true,
        }
    }
}
</script>

<style lang="sass" scoped>

.aa-lectura-img
    height: 220px
    background-size: 100%
    background-repeat: no-repeat
    background-position: center
    overflow: hidden
    margin-top: 96px
    @include breakpoint(md)
        height: 390px
.aa-lectura
    $root:&
    
    &-img
        height: 220px
        background-size: 100%
        background-repeat: no-repeat
        background-position: center
        overflow: hidden
        @include breakpoint(md)
            height: 390px
    &-txt
        padding-left: 2rem 
        padding-right: 2rem
        @include breakpoint(md)
            padding-left: 3rem
            padding-right: 3rem
    &-doc
        padding-left: 2rem 
        padding-right: 2rem
        @include breakpoint(md)
            padding-left: 3rem
            padding-right: 3rem

.aa-oferta-content
    display: flex
    flex-direction: column
    @include breakpoint(md)
        flex-direction: row
        flex-wrap: wrap
    .aa-lectura-doc
        width: 100%
        @include breakpoint(md)
            width: 50%
            
    .docs
        margin-top: 2rem
        width: 100%
        @include breakpoint(md)
            margin-top: 0
            width: 40%
</style>