<template>
    <div class="aa-skeleton-lectura mb-3 ">
        <div class="aa-skeleton-lectura-img skeleton"> </div>
        <div class="aa-skeleton-lectura-txt mt-1 mb-1 px-1 skeleton">
            
        </div>
        <div class="aa-skeleton-lectura-doc px-1 skeleton"></div>

    </div>
</template>


<style lang="sass" scoped>
.aa-skeleton-lectura
    $root:&
    
    &-img
        width: 100%
        height: 200px
    
    &-txt
        width: 90%
        height: 45px
    
    &-doc
        width: 90%
        height: 200px
        
</style>