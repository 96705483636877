import { computed, ref } from "vue"
import { useStore } from "vuex"

import publicApi from "@/api/publicApi"

const useOfertas = () => {
    const store = useStore()
    const ofertasList = ref([])
    const oferta = ref()
    const isLoading = ref(false)
    const loaded = ref(false)

    const ofertaHome = async() => {
        const data = await store.dispatch('fray/cargaOfertaEducativaHome')
        return data
    }


    const cargaOfertas = async() => {
        try {
            isLoading.value = true
            const { data } = await publicApi.get(`oferta-educativas?populate[portada][fields][0]=url`)
            if (data){
                isLoading.value = false
                ofertasList.value = data.data.map(oferta => {
                    return {
                        id: oferta.id,
                        nombre: oferta.attributes.nombre,
                        portada: (oferta.attributes.portada.data) ? oferta.attributes.portada.data.attributes.url : 'https://fraybartolome.s3.eu-west-1.amazonaws.com/ESCUDO_FRAY_27cb047579.png'
                    }
                })
            }

        } catch (error) {
            isLoading.value = false
        }
    }

    const detalleOferta = async(id) => {
        try {

            // TODO!: Documento
            isLoading.value = true
            const { data } = await publicApi.get(`oferta-educativas/${id}?populate[portada][fields][0]=url&populate[documentos][fields][1]=url, caption, size`)
            if (data){
                isLoading.value = false
                oferta.value = {
                        id: data.data.id,
                        nombre: data.data.attributes.nombre,
                        descripcion: data.data.attributes.descripcion,
                        documentos: (data.data.attributes.documentos) ? data.data.attributes.documentos.data : null,
                        portada: (data.data.attributes.portada.data) ? data.data.attributes.portada.data.attributes.url : 'https://fraybartolome.s3.eu-west-1.amazonaws.com/ESCUDO_FRAY_27cb047579.png'
                    }
            }

        }catch (error) {
            isLoading.value = false
        }
    }


    return {
        ofertaHome,
        cargaOfertas,
        detalleOferta,
        isLoading,
        loaded,
        ofertasList,
        oferta,
        ofertas: computed(() => store.state.fray.ofertaHome),
    }

}


export default useOfertas